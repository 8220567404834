.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swapfield input{
	background: none;
	border: none;
	text-align: right;
	color: #fff;
	font-weight: bold;
}
.swapfield input:hover{
	background:  none;
	color: #fff;
	
}
.swapfield input.form-control:disabled{
  background:  none;
}
.swapfield .btn.select-token{
  border-bottom-right-radius: 9px !important;
  border-top-right-radius: 9px !important;
  font-weight: bold;
  border: none;
}
.swapfield .selTokenText{
  background:#a30ab6;
  height: 100%;
  border-radius: 9px;
  padding: 14px;
}

.swapfield .btn.select-token.tokenB{
  padding: 0px ;
  
}

#combo-box-demo{
  border: 1px solid #555d66;
  color: #555d66;
}
#combo-box-demo-label{
  color: #666;
}

#searchToken .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  border: 1px solid #666;
  margin-bottom: 10px;
}
#searchToken input{
  border: none;
  margin-bottom: 10px;
  color: #ccc;
}

.css-u98okf-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  color: #666;
}
